const routes = [
    {
        name: 'home',
        path: '',
        component: () => import('@/components/HelloWorld')
    },
    {
        path: '/screen',
        component: () => import('@/components/Screen')
    }
];

export default routes


import {createRouter, createWebHashHistory} from 'vue-router'
import routes from './routes'
console.log(routes);
const router = createRouter({
    history: createWebHashHistory(),
   // base: process.env.NODE_ENV === "development" ? "/pc/" : "/",
    routes
})

export default router

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn';
import * as Icons from '@element-plus/icons-vue';
import "./assets/common.css"
import App from './App.vue'
import mitt from "mitt";
import router from './router/index';
import utils from "./lib/comm.js";
const Vue = createApp(App);
Object.keys(Icons).forEach((key) => {
    Vue.component(key, Icons[key]);
});


Vue.config.globalProperties.$bus = new mitt();
Vue.config.globalProperties.utils = utils;
Vue.use(ElementPlus,{locale});
Vue.use(router);
Vue.mount('#app')

import {ElMessageBox,ElMessage} from "element-plus";
import $ from "jquery";
import db from "@/lib/db";
import Setting from "@/setting";
import md5 from "@/lib/md5";

let ipcObej = null;
/**
 * 时间戳11位
 */
function timest() {
    var tmp = Date.parse( new Date() ).toString();
    tmp = tmp.substr(0,10);
    return tmp;
}

/**
 * 判断是否存在
 * @param {Object} value
 */
function isDefine(value) {
    if (value == null || value == "" || value == "undefined" || value == undefined || value == "null" || value == "(null)" || value == 'NULL' || typeof (value) == 'undefined') {
        return false;
    } else {
        value = value + "";
        value = value.replace(/\s/g, "");
        if (value == "") {
            return false;
        }
        return true;
    }
}

/**
 * 时间戳格式化函数
 * @param  {string} format    格式
 * @param  {int|obejct}    timestamp 要格式化的时间 默认为当前时间
 * @return {string}           格式化的时间字符串
 */
function date(format, timestamp) {
    let jsdate = null;
    if(typeof timestamp == "object") {
        jsdate = timestamp;
    } else if(typeof timestamp == "string") {
        jsdate = new Date( strtotime(timestamp) * 1000);
    } else {
        jsdate = ((timestamp) ? new Date(timestamp * 1000) : new Date());
    }
    var pad = function (n, c) {
        n = n + "";
        if (n.length < c) {
            var i = c - n.length;
            var v = '';
            for (var j = 0; j < i; j++) {
                v += '0';
            }
            return v + n;
        } else {
            return n;
        }
    };
    var txt_weekdays = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
    var txt_ordin = { "1": "st", "2": "nd", "3": "rd", "21": "st", "22": "nd", "23": "rd", "31": "st" };
    var txt_months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    var f = {
        // Day
        d: function () { return pad(f.j(), 2) },
        D: function () { return f.l().substr(0, 3) },
        j: function () { return jsdate.getDate() },
        l: function () { return txt_weekdays[f.w()] },
        N: function () { return f.w() + 1 },
        S: function () { return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th' },
        w: function () { return jsdate.getDay() }
        ,
        // Week
        W: function () {
            var a = f.z(), b = 364 + f.L() - a;
            var nd2, nd = (new Date(jsdate.getFullYear() + "/1/1").getDay() || 7) - 1;
            if (b <= 2 && ((jsdate.getDay() || 7) - 1) <= 2 - b) {
                return 1;
            } else {
                if (a <= 2 && nd >= 4 && a >= (6 - nd)) {
                    nd2 = new Date(jsdate.getFullYear() - 1 + "/12/31");
                    return date("W", Math.round(nd2.getTime() / 1000));
                } else {
                    return (1 + (nd <= 3 ? ((a + nd) / 7) : (a - (7 - nd)) / 7) >> 0);
                }
            }
        },

        // Month
        F: function () { return txt_months[f.n()] },
        m: function () { return pad(f.n(), 2) },
        M: function () { return f.F().substr(0, 3) },
        n: function () { return jsdate.getMonth() + 1 },
        t: function () {
            var n;
            if ((n = jsdate.getMonth() + 1) == 2) {
                return 28 + f.L();
            } else {
                if (n & 1 && n < 8 || !(n & 1) && n > 7) {
                    return 31;
                } else {
                    return 30;
                }
            }
        },
        //o not supported yet
        Y: function () { return jsdate.getFullYear() },
        y: function () { return (jsdate.getFullYear() + "") },

        // Time
        a: function () { return jsdate.getHours() > 11 ? "pm" : "am" },
        A: function () { return f.a().toUpperCase() },
        B: function () {
            // peter paul koch:
            var off = (jsdate.getTimezoneOffset() + 60) * 60;
            var theSeconds = (jsdate.getHours() * 3600) + (jsdate.getMinutes() * 60) + jsdate.getSeconds() + off;
            var beat = Math.floor(theSeconds / 86.4);
            if (beat > 1000) beat -= 1000;
            if (beat < 0) beat += 1000;
            if ((String(beat)).length == 1) beat = "00" + beat;
            if ((String(beat)).length == 2) beat = "0" + beat;
            return beat;
        },
        g: function () { return jsdate.getHours() % 12 || 12 },
        G: function () { return jsdate.getHours() },
        h: function () { return pad(f.g(), 2) },
        H: function () { return pad(jsdate.getHours(), 2) },
        i: function () { return pad(jsdate.getMinutes(), 2) },
        s: function () { return pad(jsdate.getSeconds(), 2) },
        //u not supported yet

        // Timezone
        //e not supported yet
        //I not supported yet
        O: function () {
            var t = pad(Math.abs(jsdate.getTimezoneOffset() / 60 * 100), 4);
            if (jsdate.getTimezoneOffset() > 0) t = "-" + t; else t = "+" + t;
            return t;
        },
        P: function () { var O = f.O(); return (O.substr(0, 3) + ":" + O.substr(3, 2)) },
        //T not supported yet
        //Z not supported yet

        // Full Date/Time
        c: function () { return f.Y() + "-" + f.m() + "-" + f.d() + "T" + f.h() + ":" + f.i() + ":" + f.s() + f.P() },
        //r not supported yet
        U: function () { return Math.round(jsdate.getTime() / 1000) }
    };

    return format.replace(/[\\]?([a-zA-Z])/g, function (t, s) {
        var ret = '';
        if (t != s) {
            ret = s;
        } else if (f[s]) {
            ret = f[s]();
        } else {
            ret = s;
        }
        return ret;
    });
}

/**
 * strtotime()函数
 * strtotime('2012-07-27 12:43:43') OR strtotime('2012-07-27')
 * @return number
 */
function strtotime(str = '') {
    if(typeof str == "object"){
        return parseInt(str.getTime() / 1000);
    }
    if(str == '') {
        var _temp = new Date();
        return parseInt(_temp.getTime() / 1000);
    }
    var _arr = str.split(' ');
    var _day = _arr[0].split('-');
    _arr[1] = (_arr[1] == null) ? '0:0:0' : _arr[1];
    var _time = _arr[1].split(':');
    for (var i = _day.length - 1; i >= 0; i--) {
        _day[i] = isNaN(parseInt(_day[i])) ? 0 : parseInt(_day[i]);
    }

    for (var i2 = _time.length - 1; i2 >= 0; i2--) {
        _time[i2] = isNaN(parseInt(_time[i2])) ? 0 : parseInt(_time[i2]);
    }

    var temp = new Date(_day[0], _day[1] - 1, _day[2], _time[0], _time[1], _time[2]);
    return parseInt(temp.getTime() / 1000);
}


/**
 * 消息时间值转化
 * @param  {int}    timestamp 要格式化的时间 默认为当前时间
 * @return {string}           格式化的时间字符串
 */
function timeLastdate(timestamp,isWeekTime = false){
    //判断是否今天
    var stoday = strtotime(date('Y-m-d 00:00:00'));

    if (timestamp > stoday){
        var h = date('H');
        if(h <= 12)
            return '早上' + date('H:i', timestamp);
        else
            return '下午' + date('H:i', timestamp);
    }
    //昨天
    var day = new Date();
    day.setTime(day.getTime() - 24 * 60 * 60 * 1000);
    var ytoday = day.getTime() / 1000;
    var s_yesterday = strtotime(date('Y-m-d 0:0:0', ytoday));
    var e_yesterday = strtotime(date('Y-m-d 23:59:59', ytoday));
    if (timestamp > s_yesterday && timestamp < e_yesterday){
        return '昨天' + date('H:i', timestamp);
    }

    //获取周一时间
    var dayInWeek = day.getDay();
    dayInWeek == 0 && (dayInWeek = 7);
    var beginWeek = strtotime(date('Y-m-d 0:0:0',(day.getTime() - (dayInWeek - 1) * 86400000) / 1000));
    if (timestamp >= beginWeek){
        return date('l', timestamp) + (isWeekTime == true ? date('h:i',timestamp) : '');
    }

    //判断是否今年
    var y  = day.getFullYear() ;
    var y1 = date('Y', timestamp);
    if(y == y1){
        return isWeekTime ? date('m月d日 H:i', timestamp) : date('m月d日', timestamp);
    }

    return date('Y年m月d日 H:i', timestamp);
}



/**
 * 加载laoding
 * @param text
 */
 function loading(page, text){
    let w = $(document).outerWidth();
    let html = "<div class='loading'><div class='mk'></div> <div class='loading-content'><i class='ivu-icon ivu-icon-ios-loading'></i><div class='loading-c'>"+text+"</div></div> </div>";
    $("body").append(html);
    let w2 = $(".loading")
        .find(".loading-content").outerWidth();
    let h2 = $(".loading")
        .find(".loading-content").outerHeight();
    $(".loading")
        .find(".loading-content")
        .css({
            'left':w/2 - w2/2
        });
    $(".loading").css('zIndex',19245);
}


/**
 * 关闭加载层..
 */
 function loadingClose(){
    $(".loading").remove();
}

/**
 * 提示框
 * @param content
 * @param title
 * @constructor
 */
function Alert(content,title,fn = null) {
    ElMessageBox.alert(content,title,{
        confirmButtonText:"确定",
        callback:(action)=>{
            if(fn != null) fn();
        }
    });
}

/**
 * 提示框架
 * @param title
 * @param fn
 * @constructor
 */
function Tip(content,time = 3000,fn = null) {
    ElMessage(title);
    if(fn != null) {
        setTimeout(()=>{fn();},time);
    }
}



/**
 * 域名信息
 * @returns {{router: *, prefix: *, query: *, domain: *}}
 */
function urlParam() {
    var myURL = new URL(window.document.location.href);
    //get参数
    var url = location.search;
    var theRequest = {};
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    myURL.query = theRequest;
    return myURL;
}


/**
 * 判断手机号码是否准确
 */
const isPoneAvailable = (tel) => {
    var myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(tel)) {
        return false;
    } else {
        return true;
    }
}

/**
 * 返回当前运行多少时间
 * @param timestamp
 */
function runTime(timestamp = 0,endtime = 0,isM = false) {
    let time = (endtime == 0 ? timest() : endtime) - timestamp;
    if(time < 0) return "";
    let day  = parseInt(time / 86400);
    time        = time % 86400;
    let housr   = parseInt(time / 3600);
    time        = time % 3600;
    let minutes = parseInt(time / 60);
    time        = time % 60;
    let seconds = time;
    let result = "";
    if(day > 0){
        result = day + '天';
    }
    result += (housr > 9 ? housr : ('0' + housr) ) + '小时'
        + (minutes > 9 ? minutes : ('0' + minutes) ) + '分钟';
    if(isM) {
        result+= (seconds > 9 ? seconds : ('0' + seconds) ) + "秒";
    }

    return  result;
}


/**
 * 返回当前运行多少时间
 * @param timestamp
 */
function secondsStr(time = 0,isM = false) {
    if(time < 0) return "";
    let day  = parseInt(time / 86400);
    time        = time % 86400;
    let housr   = parseInt(time / 3600);
    time        = time % 3600;
    let minutes = parseInt(time / 60);
    time        = time % 60;
    let seconds = time;
    let result = "";
    if(day > 0){
        result = day + '天';
    }
    if(housr > 0) {
        result += housr + '小时';
    }
    if(minutes > 0) {
        result += minutes + '分钟';
    }
    if(seconds > 0 || result == '') {
        result+= seconds + "秒";
    }

    return  result;
}

/**
 * 倒计时【验证码】
 * @time 倒计时 秒
 * @结束
 */
function CountDowm(time, fe = null) {
    var id = setInterval(() => {
        time--;
        if (fe != null) fe(time, 0);
        if (time <= 0) {
            clearInterval(id);
            fe(0, 1);
        }
    }, 1000);
}

function upHeaders() {
    let salt = Setting.key;
    let post = {};
    return post;
}


/**
 * 初始化数据
 * @param data
 * @param def
 * @returns {*}
 */
function resetFields(data, def = {}) {
    var newAr = {};
    for (var i in data) {
        if (def[i] != null) {
            newAr[i] = def[i];
        } else {
            if (typeof (data[i]) == "number") {
                newAr[i] = 0;
            } else {
                newAr[i] = '';
            }
        }
    }
    return newAr;
}



/**
 * 小数保留默认2位
 */
function returnFloat(value) {
    var value = Math.round(parseFloat(value) * 100) / 100;
    var xsd = value.toString().split(".");
    if (xsd.length == 1) {
        value = value.toString() + ".00";
        return value;
    }
    if (xsd.length > 1) {
        if (xsd[1].length < 2) {
            value = value.toString() + "0";
        }
        return value;
    }
}


/**
 * 汉字形式转化为阿拉伯形式
 * @param s_123
 * @returns {number}
 */
function chinaToNumber(s_123) {
    let map = new Map()
    map.set('一', 1)
    map.set('二', 2)
    map.set('三', 3)
    map.set('四', 4)
    map.set('五', 5)
    map.set('六', 6)
    map.set('七', 7)
    map.set('八', 8)
    map.set('九', 9)
    let split = ''
    split = s_123.split('亿')
    let s_1_23 = split.length > 1 ? split : ['', s_123]
    let s_23 = s_1_23[1]
    let s_1 = s_1_23[0]
    split = s_23.split('万')
    let s_2_3 = split.length > 1 ? split : ['', s_23]
    let s_2 = s_2_3[0]
    let s_3 = s_2_3[1]
    let arr = [s_1, s_2, s_3]
     arr = arr.map(item => {
        let result = ''
        result = item.replace('零', '')
        result = result.replace(/[一二三四五六七八九]/g, substring => {
            return map.get(substring)
        })
        let temp
        temp = /\d(?=千)/.exec(result)
        let num1 = temp ? temp[0] : '0'
        temp = /\d(?=百)/.exec(result)
        let num2 = temp ? temp[0] : '0'
        temp = /\d?(?=十)/.exec(result)
        let num3
        if (temp === null) { // 说明没十：一百零二
            num3 = '0'
        } else if (temp[0] === '') { // 说明十被简写了：十一
            num3 = '1'
        } else { // 正常情况：一百一十一
            num3 = temp[0]
        }
        temp = /\d$/.exec(result)
        let num4 = temp ? temp[0] : '0'
        return num1 + num2 + num3 + num4
    })
    return parseInt(arr.join(''))
}

/**
 * 数字转化中文
 * @param num
 */
function numberToChinese(num) {
    num=Math.floor(num);
    var chinese="";
    var digits=Math.floor(Math.log10(num))+1;
    var digit=['零','一','二','三','四','五','六','七','八','九'];
    var times=['','十','百','千','万'];
    if(digits>=9)
    {
        var quotient=Math.floor(num/Math.pow(10,8));
        var remainder=num%Math.pow(10,8);
        var remainderDigits=Math.floor(Math.log10(remainder))+1;
        return toChinese(quotient)+'亿'+(remainderDigits<8?"零":"")+(remainder>0?toChinese(remainder):"");
    }
    //10000 0000
    if(digits==1) return digit[num];
    if(digits==2)
    {
        var quotient=Math.floor(num/10);
        var remainder=num%10;
        if(quotient>1)
        {
            chinese=digit[quotient];
        }
        chinese=chinese+times[1];
        if(remainder>0)
        {
            chinese=chinese+digit[remainder];
        }
        return chinese;
    }
    if(digits>5)
    {
        var quotient=num/Math.pow(10,4);
        var remainder=num%Math.pow(10,4);
        var remainderDigits=Math.floor(Math.log10(remainder))+1;
        return toChinese(quotient)+'万'+(remainderDigits<4?"零":"")+(remainder>0?toChinese(remainder):"");
    }
    for(var index=digits;index>=1;index--)
    {
        var number=Math.floor(num/Math.pow(10,index-1)%10);
        //console.log(index+" "+number);
        if(number>0)
        {
            chinese=chinese+digit[number]+times[index-1];
        }
        else
        {
            if(index>1)
            {
                var nextNumber=Math.floor(num/Math.pow(10,index-2)%10);
                if(nextNumber>0&&index>1)
                {
                    chinese=chinese+digit[number];
                }
            }
        }
    }
    return chinese;
}

export default {
    isDefine        : isDefine,
    date 			: date,//时间戳解析时间
    strtotime   	: strtotime,//日期解析时间戳
    timest			: timest,//获取11位时间戳
    timeLastdate    : timeLastdate,
    loading         : loading,
    loadingClose    : loadingClose,
    Alert           : Alert,//消息提示
    Tip             : Tip,//其他方式提示
    resetFields     : resetFields,//重置表单
    urlParam        : urlParam,
    isPoneAvailable : isPoneAvailable,
    CountDowm       : CountDowm,//倒计时
    upHeaders       : upHeaders,
    returnFloat     : returnFloat,//小数二位
    numberToChinese : numberToChinese,//数字转化中文
    chinaToNumber   : chinaToNumber,//中文转化数字
    runTime         : runTime,//运行时间
    secondsStr      : secondsStr,//秒转化中文时间
}

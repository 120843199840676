import {Low} from 'lowdb';
import {LocalStorage} from "lowdb/browser";

export default {
    itemkey : "zw-chw-tl",
    data:{},

    async init(){
      //const adapter = new LocalStorage("zw-chw-tl");
      //this.db = new Low(adapter);
      //await this.db.read();
      //this.db.data ||= {};
        var data = localStorage.getItem(this.itemkey) || "";
        try{
            if(data == null || data == "")
                this.data = {};
            else
                this.data = JSON.parse(data);
        }  catch (e) {
            console.log(e.message);
        }
    },
   /**
    * 从本地缓存中同步获取指定 key 对应的内容
    * @param key
    */
   getStorageSync(key){

      if(this.data[key] == null) return null;
      return this.data[key];
   },

   /**
    * 将 data 存储在本地缓存中指定的 key 中.
    * @param key
    * @param value
    */
   setStorageSync(key, value) {
      this.data[key] = value;
      this.save();
   },
   /**
    * 删除本地存储的
    * @param key
    */
    removeStorageSync(key){
      delete this.data[key];
      this.save();
      //localStorage.removeItem(key);
   },

    save(){
        localStorage.setItem(this.itemkey, JSON.stringify(this.data));
    }
}
